import { provideIntoWindow } from "@b2c/core/app/default/provide-into-window.js";
import { brandConfig } from "../index.js";
import { $_ALTENARCONFIG } from "./../altenar.mjs";

const pgwUrl = 'https://app.payment-gateway.io';

const productionConfig = {
  pgwConfig: {
    pgwUrl: pgwUrl,
    scriptUrl: pgwUrl + '/default/index.js',
    pgwPaymentLightSvg: pgwUrl + '/assets/logos/light/large/',
    pgwPaymentDarkSvg: pgwUrl + '/assets/logos/dark/large/',
  }
};

provideIntoWindow("$_ALTENARCONFIG", $_ALTENARCONFIG);

export const prod = provideIntoWindow(
  "$_CONFIG",
  Object.assign({}, brandConfig, productionConfig)
);